import React, { useState, useEffect } from 'react'
import styled from "styled-components"
import axios from 'axios'

import StepIcon from '../../../assets/images/svg/back-arrow.svg';

const Container = styled.div`
  padding: 0 8px;
`

const Header = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 24px;

  span {
    font-size: 14px;
    line-height: 14px;
    margin-left: 6px;
  }
`

const Content = styled.div`
  height: 330px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  h4 {
    margin-bottom: 8px
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: #285056;
  }
`
const Footer = styled.div`

  margin-top: 32px;

  div:last-child {
    margin-bottom: 0;
  }

  div {
    margin-bottom: 16px;

    input {
      width: calc(100% - 16px);
      height: 30px;
      padding: 4px 8px;
      font-family: var(--regular-font);
      font-size: 14px;
      border: 1px solid #EEF2F5;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
      border-radius: 6px;
      outline: none;
  
      &:focus {
        border: 1px solid rgba(130, 130, 130, 0.2);
        outline: none;
      }
      &:disabled {
        opacity: 0.7;
        background-color: #fff;
      }
    }
    
    .prompt {
      display: inline-block;
      margin-top: 4px;
      font-size: 13px;
      color: #5e6469;
      padding-left: 4px;
    }

    label {
      font-weight: 500;
      font-size: 13px;
      display: inline-block;
      line-height: 16px;
      margin-bottom: 8px;
    }
  
    button {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 20px;
    }
  }
`

const Spinner = styled.span`
  width: 13px;
  height: 13px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  border-top: 2px solid #fff;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`

const StepTwo = props => {

  const [amount, setAmount] = useState('')
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [inputIsValid, setInputIsValid] = useState(false)
  const [cachedResponse, setcachedResponse] = useState(false)
  const url = process.env.GATSBY_MIDDLEWARE_URL

  const setCodeString = (amount, email) => {
    amount = amount.length <= 0 ? '_' : amount
    email = email.length <= 0 ? '_' : email
    const curl = `curl https://api.paystack.co/transaction/initialize 
    -H "Authorization: Bearer ${props.secretKey}"
    -H "Content-Type: application/json"
    -d {"amount": ${amount}, "email": ${email}}
    -X POST
    `

    props.updateCode(curl)
  }

  const checkRecap = () =>{
    if(Object.keys(props.recap).length < 3 && props.recap.constructor === Object) {
      setcachedResponse(false)
    } else {
      let request = props.recap.initialize.request
      let cachedAmount = request.amount
      let cachedEmail = request.email

      setcachedResponse(true)
      setAmount(cachedAmount)
      setEmail(cachedEmail)

      validateForm(cachedAmount, cachedEmail)
    }
  }

  const initialize = () => {
    if(cachedResponse) {
      loadCachedResponse()
    } else {
      setLoading(true)
      const data = {amount: amount, email: email}
      axios.post(`${url}/transaction/initialize`, data).then(res => {
        props.updateRequest(amount, email, res.data.data.reference, res.data.data.authorization_url, res.data.data.access_code)
        props.updateCode(JSON.stringify(res.data, null, '  '))
      }).catch(error => {
        props.updateCode(JSON.stringify(error.response.data.message, null, '  '))
      }).then(() => {
        setLoading(false)
        props.updateLanguage('json')
        props.changeStep(3)
      })
    }
  }

  const loadCachedResponse = () => {
    let response = props.recap.initialize.response

    props.updateRequest(amount, email, response.data.reference, response.data.authorization_url, response.data.access_code)
    props.updateCode(JSON.stringify(response, null, '  '))
    props.updateLanguage('json')
    props.changeStep(3)
  }

  const updateAmount = (event) => {
    setAmount(event.target.value)
    setCodeString(event.target.value, email)
    validateForm(event.target.value, email)
  }

  const updateEmail = (event) => {
    setEmail(event.target.value)
    setCodeString(amount, event.target.value)
    validateForm(amount, event.target.value)
  }

  const validateForm = (tempAmount, tempEmail) => {
    setInputIsValid(amountIsValid(tempAmount) && emailIsValid(tempEmail))
  }

  const amountIsValid = (tempAmount) => {
    return tempAmount != null && parseInt(tempAmount) >= 1000
  }

  const emailIsValid = (tempEmail) => {
    const regex = /\S+@\S+\.\S+/

    return tempEmail != null && tempEmail.length > 0 && regex.test(tempEmail)
  }

  useEffect(() => {
    props.setHeight('350px')
    checkRecap()
    setCodeString(amount, email)
  }, [amount, email])

  return (
    <Container>
      <Header onClick={() => props.changeStep(1)}>
        <StepIcon />
        <span>Back</span>
      </Header>
      <Content>
        <div>
          <h4>Enter Payment details</h4>
          <p>Helps us send transaction receipts to the customer</p>
        </div>
        <Footer>
          <div>
            <label>Charge Amount (in kobo, pesewas, or cents)</label>
            <input type="number" placeholder="e.g. 50000" value={amount} onChange={updateAmount} disabled={ cachedResponse } />
            <span className="prompt">Minimum amount is 1000</span>
          </div>
          <div>
            <label>Customer Email Address</label>
            <input type="email" placeholder="e.g. mail@example.com" value={email} onChange={updateEmail} disabled={ cachedResponse } />
          </div>
          <div>
            <button className="button" onClick={() => initialize()} disabled={ !inputIsValid || loading }>
              {loading ? <Spinner /> : cachedResponse ? 'Next' : 'Send Request' }
            </button>
          </div>
        </Footer>
      </Content>
    </Container>
  )
}

export default StepTwo