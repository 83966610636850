import React from 'react';
import { Link } from 'gatsby';
import styled from "styled-components"

const Card = styled(Link)`
  background: #ffffff;
  border: 1px solid #0000000f;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  transition: 0.3s ease-in box-shadow, 0.3s ease-in border-color;

  &:hover {
    border: 1px solid $opaque-gray;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);

    .home-illustration {
      .dark-blue {
        fill:#011b33;
      }
      .blue {
        fill: #00e1ff;
      }
      .light-blue {
        fill: #00c3f7;
      }
      .green {
        fill: #9fffb3;
      }
      .dark-green {
        fill: #75f997;
      }
      .dark-grey {
        fill: #223447
      }
    }
  }

  .quick_start_header {
    svg {
      width: 340px;
      height: 130px;

      path, rect, circle, ellipse {
        // transition: fill 0.25s ease-in-out;
        transition: fill 0.25s cubic-bezier(0.01, 0.1, 0.58, 1) 0.01s
      }
    }
  }

  .quick_start_body {
    padding: 15px 20px 20px;

    h4 {
      margin-top: 0;
    }

    p {
			line-height: 1.45;
			margin-top: 0;
			color: #011b33;
		}
  }

  @media screen and (max-width: 809px) {
		.quick_start_header {
      svg {
        width: calc((809px - 65px) / 2);
        height: auto;
      }
    }
  }
  
  @media screen and (min-width: 768px) and (max-width: 808px) {
		.quick_start_header {
      svg {
        width: calc((768px - 65px) / 2);
        height: auto;
      }
    }
  }
  
  @media screen and (max-width: 767px) {
    display: block;
    margin-bottom: 20px;

		.quick_start_header {
      svg {
        width: 90vw;
        height: auto;
      }
    }
	}
`


const QuickStartCard = props => {
	return (
		<Card to={props.route}>
      <div className="quick_start_header">
        {props.image}
      </div>
			<div className="quick_start_body">
				<h4>{props.title}</h4>
        <p>{props.description}</p>
			</div>
		</Card>
	);
};

export default QuickStartCard;
