import React from 'react';
import { Link } from 'gatsby';

import Github from '../../assets/images/svg/github.svg';
import './card.scss';

const DemoCard = props => {
	const baseUrl = 'https://github.com/'

	return (
		<div className={`card ${ props.disabled ? 'card--disabled' : '' }`}>
			<div className="card__header">
				<h5>{props.title}</h5>
			</div>
			<div className="card__description">
				<Github className="card__description__icon" />
				<span>
					{props.disabled ? (
						<span
							className="card__description__link">
							{props.link}
						</span>
					) : (
						<a
							className="card__description__link"
							href={ baseUrl + props.link }
							target="_blank"
							rel="noopener noreferrer">
							{props.link}
						</a>
					)}
				</span>
			</div>
			<div className="card__body">
				<span className="card__body__prompt">APIS USED</span>
				<hr className="card__body__line" />
			</div>
			<div className="card__footer">
				<ul className="links">
					{props.apis.map((link, index) => (
						<li className="links__item" key={index}>
							<Link to={link.route} className="links__item__link">
								{link.title}
							</Link>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default DemoCard;
