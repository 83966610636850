import React from 'react'
import styled from 'styled-components';

const H1 = styled.h1`
  position: relative;
`
const H2 = styled.h2`
  position: relative;
`
const H3 = styled.h3`
  position: relative;
`
const H4 = styled.h4`
  position: relative;
`
const H5 = styled.h5`
  position: relative;
`
const H6 = styled.h6`
  position: relative;
`

const Permalink = ({type, title, id}) => {
  const Anchor = <a href={`#${id}`} aria-label="redirect permalink" className="anchor before"><svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>
  
  const heading = {
    h1: <H1 id={ id }>{ Anchor }{ title }</H1>,
    h2: <H2 id={ id }>{ Anchor }{ title }</H2>,
    h3: <H3 id={ id }>{ Anchor }{ title }</H3>,
    h4: <H4 id={ id }>{ Anchor }{ title }</H4>,
    h5: <H5 id={ id }>{ Anchor }{ title }</H5>,
    h6: <H6 id={ id }>{ Anchor }{ title }</H6>
  }

  return (
    <>
      { heading[type] }
    </>
  )
}

export default Permalink