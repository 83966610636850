import React, { useState, useEffect } from 'react'
import styled from "styled-components"
import axios from 'axios'

import StepIcon from '../../../assets/images/svg/back-arrow.svg';

const Container = styled.div`
  padding: 0 8px;
`

const Header = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 24px;

  span {
    font-size: 14px;
    line-height: 14px;
    margin-left: 6px;
  }
`

const Content = styled.div`
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  h4 {
    margin-bottom: 8px
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: #285056;
  }
`
const Footer = styled.div`
  div:last-child {
    margin-bottom: 0;
  }

  div {
    margin-bottom: 8px;

    input {
      width: calc(100% - 16px);
      height: 30px;
      padding: 4px 8px;
      font-family: var(--regular-font);
      font-size: 14px;
      border: 1px solid #EEF2F5;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
      border-radius: 6px;
      outline: none;
  
      &:focus {
        border: 1px solid rgba(130, 130, 130, 0.2);
        outline: none;
      }
      &:disabled {
        opacity: 0.7;
        background-color: #fff;
      }
    }

    .prompt {
      display: inline-block;
      margin-top: 4px;
      font-size: 13px;
      color: #5e6469;
      padding-left: 4px;
    }

    select {
      width: 100%;
      height: 35px;
      padding: 4px 8px;
      font-family: var(--regular-font);
      font-size: 14px;
      border: 1px solid #EEF2F5;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
      border-radius: 6px;
      outline: none;
    }

    label {
      font-weight: 500;
      font-size: 13px;
      display: inline-block;
      margin-bottom: 4px;
    }
  
    button {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 20px;
    }
  }
`

const Spinner = styled.span`
  width: 13px;
  height: 13px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  border-top: 2px solid #fff;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`

const StepFour = props => {
  const [amount, setAmount] = useState('')
  const [loading, setLoading] = useState(false)
  const [inputIsValid, setInputIsValid] = useState(false)
  const [cachedResponse, setcachedResponse] = useState(false)
  const url = process.env.GATSBY_MIDDLEWARE_URL

  const setCodeString = (amount) => {
    const curl = `curl https://api.paystack.co/transfer 
    -H "Authorization: Bearer ${props.secretKey}"
    -H "Content-Type: application/json"
    -d {"source": 'balance', "recipient": '${props.recipientCode}', 
      "amount": ${amount.length === 0 ? '_' : amount} }
    -X POST
    `

    props.updateCode(curl)
    props.updateLanguage('bash')
  }

  const checkRecap = () =>{
    if(Object.keys(props.recap).length === 0 && props.recap.constructor === Object) {
      setCodeString('')
      setcachedResponse(false)
    } else {
      let request = props.recap.transfer.request
      let tempAmount = request.amount

      setAmount(tempAmount)
      setCodeString(tempAmount)
      validateForm(tempAmount)
      setcachedResponse(true)
    }
  }

  const loadCachedResponse = () => {
    let response = props.recap.transfer.response
    props.updateCode(JSON.stringify(response, null, '  '))
    props.updateLanguage('json')
    props.changeStep(5)
  }

  const transferFund = () => {
    if(cachedResponse) {
      loadCachedResponse()
    } else {
      setLoading(true)
      const data = {
        source: 'balance',
        recipient: props.recipientCode,
        amount: amount
      }

      axios.post(`${url}/transfer`, data).then(res => {
        props.updateCode(JSON.stringify(res.data, null, '  '))
        props.updateLanguage('json')
      }).catch(error => {
        props.updateCode(JSON.stringify(error.response.data, null, '  '))
        props.updateLanguage('json')
      }).then(() => {
        setLoading(false)
        props.changeStep(5)
      })
    }
  }

  const updateAmount = (event) => {
    setAmount(event.target.value)
    setCodeString(event.target.value)
    validateForm(event.target.value)
  }

  const validateForm = (tempAmount) => {
    setInputIsValid( tempAmount != null && parseInt(tempAmount) >= 1000)
  }

  useEffect(() => {
    props.updateEndpoint('transfer')
    props.setHeight('320px')
    checkRecap()
  }, []);


  return (
    <Container>
      <Header onClick={() => props.changeStep(3)}>
        <StepIcon />
        <span>Back</span>
      </Header>
      <Content>
        <div>
          <h4>Transfer Fund</h4>
          <p>Enter an amount to transfer to the recipient</p>
        </div>
        <Footer>
           <div>
            <label>Account name</label>
            <input type="text" value={props.accountName} disabled />
          </div>
          <div>
            <label>Amount (in kobo, pesewas or cents)</label>
            <input type="number" placeholder="50000" value={amount} onChange={updateAmount} disabled={ cachedResponse } />
            <span className="prompt">Minimum amount is 1000</span>
          </div>
          <div>
            <button className="button" onClick={() => transferFund()} disabled={ !inputIsValid || loading}>
              {loading ? <Spinner /> :  cachedResponse ? 'Next' : 'Complete Transfer' }
            </button>
          </div>
        </Footer>
      </Content>
      
    </Container>
  )
}


export default StepFour