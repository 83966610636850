import React, { useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import StepIcon from '../../../assets/images/svg/info.svg';

const Container = styled.div`
  padding: 0px 8px;
`
const Header = styled.div`
  margin-bottom: 10px;
`

const Content = styled.div`
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h4 {
    margin-bottom: 12px
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: #285056;
  }
`
const Footer = styled.div`

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      padding-bottom: 8px;
      margin-bottom: 8px;
      border-bottom: 1px solid rgba(130, 130, 130, 0.15);
      cursor: pointer;
    }

    li:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    button {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    svg {
      width: 8px;
      height: 8px;

      path {
        stroke: #285056;
      }
    }
  }
`

const StepOne = props => {
  const url = process.env.GATSBY_MIDDLEWARE_URL

  const fetchRecap = () => {
    axios.get(`${url}/transaction/recap`).then(res => {
      props.setRecap(res.data)
    }).catch( () => {
      props.setRecap({})
    })
  }

  const autoGenerateKey = () => {
    props.updateSecretKey('sk_test_DEFAULT')
    setCodeString('sk_test_DEFAULT')
    props.changeStep(2)
  }

  const setCodeString = (secret_key) => {
    const curl = `curl https://api.paystack.co/transaction/initialize 
    -H "Authorization: Bearer ${secret_key}"
    -H "Content-Type: application/json"
    -X POST
    `

    props.updateCode(curl)
    props.updateLanguage('bash')
  }

  useEffect(() => {
    props.setHeight('240px')
    fetchRecap()
  }, []);


  useEffect(() => {
    setCodeString(props.secretKey)
  }, [props.secretKey]);

  return (
    <Container>
      <Header>
        <StepIcon />
      </Header>
      <Content>
        <div>
          <h4>Before you begin</h4>
          <p>Authenticate all Paystack API calls using your secret keys</p>
        </div>
        <Footer>
          <ul>
            <li onClick={() => autoGenerateKey()}>
              <button type="button" className="button">Next</button>
            </li>
          </ul>
        </Footer>
      </Content>
    </Container>
  )
}


export default StepOne