import React, { useState } from 'react'
import styled from "styled-components"

import LandingPage from './StepFive/LandingPage'
import ChargeAuth from './StepFive/ChargeAuth'

const Container = styled.div`
  padding: 0 8px;
`

const StepFive = props => {
  const [currentStep, updateCurrentStep] = useState(1)

  const updateStep = (step) => {
    updateCurrentStep(step)
  }

  const views = [ 
    <LandingPage changeView={updateStep} updateEndpoint={props.updateEndpoint} updateLanguage={props.updateLanguage} changeStep={props.changeStep} />, 
    <ChargeAuth recap={props.recap} setHeight={props.setHeight} secretKey={props.secretKey} authCode={props.authCode} email={props.email}
      updateCode={props.updateCode} updateLanguage={props.updateLanguage} changeStep={props.changeStep} /> 
  ] 

  return (
    <Container>
      {views[currentStep - 1]}
    </Container>
  )
}


export default StepFive