import React, { useState, useEffect } from 'react'
import styled from "styled-components"

import StepIcon from '../../../assets/images/svg/back-arrow.svg';

const Container = styled.div`
  padding: 0 8px;
`

const Header = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 24px;

  span {
    font-size: 14px;
    line-height: 14px;
    margin-left: 6px;
  }
`

const Content = styled.div`
  height: 235px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  h4 {
    margin-bottom: 8px
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: #285056;
  }
`
const Footer = styled.div`
  div:last-child {
    margin-bottom: 0;
  }

  div {
    margin-bottom: 8px;

    select {
      width: 100%;
      height: 35px;
      padding: 4px 8px;
      font-family: var(--regular-font);
      font-size: 14px;
      border: 1px solid #EEF2F5;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
      border-radius: 6px;
      outline: none;
    }

    label {
      font-weight: 500;
      font-size: 13px;
      display: inline-block;
      margin-bottom: 4px;
    }
  
    button {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 20px;
    }
  }
`

const StepTwo = props => {

  const [cachedResponse, setcachedResponse] = useState(false)

  const checkRecap = () =>{
    if(Object.keys(props.recap).length === 0 && props.recap.constructor === Object) {
      setcachedResponse(false)
    } else {
      loadCachedResponse()
      setcachedResponse(true)
    }
  }

  const loadCachedResponse = () => {
    let request = props.recap.bank_country.request
    let country = request.split('=')[1]
    country = country.charAt(0).toUpperCase() + country.slice(1)

    props.setCountry(country)
  }

  const updateCountry = (event) => {
    props.setCountry(event.target.value)
  }
  
  useEffect(() => {
    checkRecap()
  }, [props.country])

  return (
    <Container>
      <Header onClick={() => props.changeStep(1)}>
        <StepIcon />
        <span>Back</span>
      </Header>
      <Content>
        <div>
          <h4>Select Country</h4>
          <p>Select the country of your transfer recipient</p>
        </div>
        <Footer>
          <div>
            <label>Country</label>
            <select value={props.country} onChange={updateCountry} disabled={cachedResponse}>
              {cachedResponse ? 
                <option value={props.country.toLowerCase()}>{props.country}</option> : 
                <option value="">Select country</option>
              }
              {props.countries.map((country, index) => (
                <option key={index} value={country.name.toLowerCase()}>{country.name}</option>
              ))}
            </select>
          </div>
          <div>
            <button className="button" onClick={() => props.changeStep(3)} disabled={ props.country.length <= 0 }>
              {cachedResponse ? 'Next' : 'Send Request'}
            </button>
          </div>
        </Footer>
      </Content>
      
    </Container>
  )
}


export default StepTwo