import React, { useEffect } from 'react'
import styled from "styled-components"

import StepIcon from '../../../assets/images/svg/back-arrow.svg';
import Restart from '../../../assets/images/svg/restart.svg';

const Container = styled.div`
  padding: 0 8px;
`

// const Header = styled.div`
//   width: fit-content;
//   display: flex;
//   margin-bottom: 18px;
//   cursor: pointer;
  
//   span {
//     margin-right: 8px;
//   }
// `
const Header = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 24px;

  span {
    font-size: 14px;
    line-height: 14px;
    margin-left: 6px;
  }
`

const Content = styled.div`
  height: 235px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  h4 {
    margin-bottom: 8px
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: #285056;
  }
`
const Footer = styled.div`
  button {
    display: flex;
    justify-content: center;
    width: 100%;

    span:last-child {
      margin-left: 4px;
    }
  }
}
`

const StepFive = props => {

  const recap = () => {
    props.updateEndpoint('transferrecipient')
    props.updateSecretKey('YOUR_SECRET_KEY')
    props.changeStep(1)
  }

  useEffect(() => {
    props.setHeight('235px')
  }, []);

  return (
    <Container>
      <Header onClick={() => props.changeStep(4)}>
        <StepIcon />
        <span>Back</span>
      </Header>
      <Content>
        <div>
          <h4>Transfer Sent 🎉</h4>
          <p>You’ve successfully completed the Paystack Transfer Payment flow.</p>
        </div>
        <Footer>
          <div>
            <button className="button" onClick={() => recap() }>
              <span>Recap</span>
              <span><Restart /></span>
            </button>
          </div>
        </Footer>
      </Content>
    </Container>
  )
}


export default StepFive