import React, { useState, useEffect } from 'react'
import styled from "styled-components"
import axios from 'axios'

import StepIcon from '../../../../assets/images/svg/back-arrow.svg';

const Header = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 24px;

  span {
    font-size: 14px;
    line-height: 14px;
    margin-left: 6px;
  }
`

const Content = styled.div`
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  h4 {
    margin-bottom: 8px
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: #285056;
  }
`
const Footer = styled.div`
  div:last-child {
    margin-bottom: 0;
  }

  div {
    margin-bottom: 16px;

    input {
      width: calc(100% - 16px);
      height: 30px;
      padding: 4px 8px;
      font-family: var(--regular-font);
      font-size: 14px;
      border: 1px solid #EEF2F5;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
      border-radius: 6px;
      outline: none;

      &:focus {
        border: 1px solid rgba(130, 130, 130, 0.2);
        outline: none;
      }
      &:disabled {
        opacity: 0.7;
        background-color: #fff;
      }
    }

    .prompt {
      display: inline-block;
      margin-top: 4px;
      font-size: 13px;
      color: #5e6469;
      padding-left: 4px;
    }

    label {
      font-weight: 500;
      font-size: 13px;
      display: inline-block;
      margin-bottom: 4px;
    }

    button {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
`

const Spinner = styled.span`
  width: 13px;
  height: 13px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  border-top: 2px solid #fff;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`

const ChargeAuth = props => {

  const url = process.env.GATSBY_MIDDLEWARE_URL
  const [loading, setLoading] = useState(false)
  const [amount, setAmount] = useState('')
  const [cachedResponse, setcachedResponse] = useState(false)

  const setCodeString = (amount) => {
    amount = amount.length <= 0 ? '_' : amount
    const curl = `curl https://api.paystack.co/transaction/charge_authorization 
    -H "Authorization: Bearer ${props.secretKey}"
    -H "Content-Type: application/json"
    -d {"amount": ${amount}, "email": ${props.email}, \n "authorization_code": ${props.authCode}}
    -X POST
    `

    props.updateCode(curl)
    props.updateLanguage('bash')
  }

  const updateAmount = (event) => {
    setAmount(event.target.value)
    setCodeString(event.target.value, props.email)
  }

  const checkRecap = () =>{
    if(!(Object.keys(props.recap).length === 0 && props.recap.constructor === Object)) {
      let request = props.recap.charge_authorization.request
      let cachedAmount = request.amount

      setcachedResponse(true)
      setAmount(cachedAmount)
    }
  }

  const chargeAuth = () => {
    if(cachedResponse) {
      loadCachedResponse()
    } else {
      setLoading(true)

      const data = {amount: amount, email: props.email, authorization_code: props.authCode}
      axios.post(`${url}/transaction/charge_authorization`, data).then(res => {
        props.updateCode(JSON.stringify(res.data, null, '  '))
      }).catch(error => {
        props.updateCode(JSON.stringify(error.response.data.message, null, '  '))
      }).then(() => {
        setLoading(false)
        props.updateLanguage('json')
        props.changeStep(6)
      })
    }
  }

  const loadCachedResponse = () => {
    let response = props.recap.charge_authorization.response
    props.updateCode(JSON.stringify(response, null, '  '))
    props.updateLanguage('json')
    props.changeStep(6)
  }

  useEffect(() => {
    props.setHeight('250px')
    checkRecap()
    setCodeString(amount)
  }, [amount])

  return (
    <>
      <Header onClick={() => props.changeStep(4)}>
        <StepIcon />
        <span>Back</span>
      </Header>
      <Content>
        <div>
          <h4>Charge Authorization</h4>
          <p>Enter an amount to charge this card again.</p>
        </div>
        <Footer>
          <div>
            <label>Charge amount (in kobo, pesewas, or cents)</label>
            <input type="number" placeholder="500000" value={amount} onChange={updateAmount} disabled={ cachedResponse } />
            <span className="prompt">Minimum amount is 1000</span>
          </div>
          <div>
            <button className="button" onClick={() => chargeAuth()} disabled={ amount.length < 3 || parseInt(amount) < 1000 || loading }>
              {loading ? <Spinner /> : cachedResponse ? 'Next' : 'Charge same card' }
            </button>
          </div>
        </Footer>
      </Content>
    </>
  )
}


export default ChargeAuth