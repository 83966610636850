import React from 'react'
import styled from "styled-components"
import Highlight, { defaultProps } from 'prism-react-renderer'
import DarkTheme from 'prism-react-renderer/themes/nightOwl'
import LightTheme from 'prism-react-renderer/themes/nightOwlLight'

const codeTheme = {
  dark: DarkTheme,
  light: LightTheme
}

const Container = styled.div`
  padding: 0px 16px;
`

const Pre = styled.pre`
  position: relative;
  // text-align: left;
  padding: 24px 16px;
  margin: 16px 0;
  overflow-x: auto;
  border: 1px solid #EEF2F5;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  background-color: ${props => props.dark ? '#31485F' : '#FFF'};
  height: ${props => props.height};
  width: 460px;

  & .token-line {
    line-height: 24px;
  }

  @media screen and (max-width: 809px) {
		width: calc((809px - 90px) * 0.7)
  }
  
  @media screen and (min-width: 768px) and (max-width: 808px) {
		width: calc((768px - 80px) * 0.7)
  }
  
  @media screen and (max-width: 767px) {
    width: 83vw;
	}
`

const LineNo = styled.span`
  display: inline-block;
  width: 2em;
  user-select: none;
  opacity: 0.3;
`


const CodeSnippet = ({ height, language, codeString, theme }) => {
  codeString = language === 'bash' ? codeString.replace(/  +/g, '') : codeString
  

  return (
    <Container>
      <Highlight {...defaultProps} theme={codeTheme[theme]} code={codeString} language={language}>
        {({ tokens, getLineProps, getTokenProps }) => (
          <Pre dark={theme === 'dark'} height={height}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                <LineNo>{i + 1}</LineNo>
                {line.map((token, key) => <span {...getTokenProps({ token, key })} />)}
              </div>
            ))}
          </Pre>
        )}
      </Highlight>
    </Container>
  )
}


export default CodeSnippet