import React from 'react'
import styled from "styled-components"

import StepIcon from '../../../../assets/images/svg/back-arrow.svg';

const Header = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 24px;

  span {
    font-size: 14px;
    line-height: 14px;
    margin-left: 6px;
  }
`

const Content = styled.div`
  height: 235px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  h4 {
    margin-bottom: 8px
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: #285056;
  }
`
const Footer = styled.div`
  input {
    width: calc(100% - 16px);
    height: 30px;
    padding: 4px 8px;
    font-family: var(--regular-font);
    font-size: 14px;
    border: 1px solid #EEF2F5;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    outline: none;

    &:focus {
      border: 1px solid rgba(130, 130, 130, 0.2);
      outline: none;
    }
  }
  span {
    font-size: 11px;
    display: inline-block;
    margin-bottom: 16px;
  }

  button {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
`

const LandingPage = props => {

  const updateView = () => {
    props.updateEndpoint('charge_auth')
    props.updateLanguage('bash')
    props.changeView(2)
  }

  return (
    <>
      <Header onClick={() => props.changeStep(4)}>
        <StepIcon />
        <span>Back</span>
      </Header>
      <Content>
        <div>
          <h4>Try Charge Auth</h4>
          <p><strong>Transaction successful!</strong> Now you can charge this card in the future, directly from the API. Click continue to demo this.</p>
        </div>
        <Footer>
          <div>
            <button className="button" onClick={() => updateView()}>Continue</button>
          </div>
        </Footer>
      </Content>
      
    </>
  )
}


export default LandingPage