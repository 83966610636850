import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import StepIcon from '../../../assets/images/svg/back-arrow.svg';
import Arrow from '../../../assets/images/svg/link.svg';

const Container = styled.div`
  padding: 0 8px;
`

const Header = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 24px;

  span {
    font-size: 14px;
    line-height: 14px;
    margin-left: 6px;
  }
`

const Content = styled.div`
  height: 235px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  h4 {
    margin-bottom: 8px
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: #285056;
  }
`
const Footer = styled.div`
  div:last-child {
    margin-bottom: 0;
  }

  div {

    input {
      width: calc(100% - 16px);
      height: 30px;
      padding: 4px 8px;
      font-family: var(--regular-font);
      font-size: 14px;
      border: 1px solid #EEF2F5;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
      border-radius: 6px;
      outline: none;
  
      &:focus {
        border: 1px solid rgba(130, 130, 130, 0.2);
        outline: none;
      }
    }

    label {
      font-weight: 500;
      font-size: 13px;
    }
  
    button {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
    }
  }
`

const StepThree = props => {

  const [cachedResponse, setcachedResponse] = useState(false)

  useEffect(() => {
    props.setHeight('235px')
    checkRecap()
  }, [props.recap]);

  const checkRecap = () =>{
    if(Object.keys(props.recap).length === 0 && props.recap.constructor === Object) {
      setcachedResponse(false)
    } else {
      setcachedResponse(true)
    }
  }

  const sendRequest = () => {
    if(!cachedResponse) {
      const paystack = new window.PaystackPop()
      paystack.resumeTransaction(props.access_code)
    }
    props.changeStep(4)
    props.updateEndpoint('verify')
  }
  
  return (
    <Container>
      <Helmet>
        <script src="https://js.paystack.co/v2/inline.js"></script>
      </Helmet>
      <Header onClick={() => props.changeStep(2)}>
        <StepIcon />
        <span>Back</span>
      </Header>
      <Content>
        <div>
          <h4>Complete Payment</h4>
          <p>Click the button below, to load the Paystack checkout and complete the transaction</p>
        </div>
        <Footer>
          <div>
            <button className="button" onClick={() => sendRequest()}>
              {cachedResponse ? 'Next' 
              : 
                <>
                  <span>Complete Payment</span>
                  <span><Arrow /></span>
                </>
              }
            </button>
          </div>
        </Footer>
      </Content>
    </Container>
  )
}


export default StepThree