import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
	margin: 30px 0;

	${({ space }) =>
		space &&
		`
    margin: 15px 0;
  `}

	@media screen and (max-width: 809px) {
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (max-width: 767px) {
		display: block;
		div, a {
			margin-bottom: 20px;
		}

		a.card {
			display: block;
		}
	}
`;

const CardContainer = ({ space, children }) => {
	return <Container space={space}>{children}</Container>;
};

export default CardContainer;
