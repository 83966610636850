import React, { useState } from 'react'
import styled from "styled-components"

import StepOne from './Steps/StepOne'
import StepTwo from './Steps/StepTwo'
import StepThree from './Steps/StepThree'
import StepFour from './Steps/StepFour'
import StepFive from './Steps/StepFive'
import StepSix from './Steps/StepSix'


const Container = styled.div`
  padding: 16px 8px 0px;
`

const Steps = ({ setHeight, updateEndpoint, updateLanguage, updateCode }) => {
  const [currentStep, updateCurrentStep] = useState(1)
  const [secretKey, setSecretKey] = useState('YOUR_SECRET_KEY')
  const [authCode, setAuthCode] = useState('')
  const [request, setRequest] = useState({})
  const [recap, setRecap] = useState({})

  const endpoints = {
    initialize: { method: 'post', path: '/transaction/initialize' },
    verify: { method: 'get', path: '/transaction/verify/:reference' },
    charge_auth: { method: 'post', path: '/transaction/charge_authorization' }
  }

  const updateStep = (step) => {
    updateCurrentStep(step)
  }

  const updateSecretKey = (key) => {
    setSecretKey(key)
  }

  const updateCurrentEndpoint = (type) => {
    updateEndpoint(endpoints[type])
  }

  const updateAuthCode = (code) => {
    setAuthCode(code)
  }

  const updateRequest = (amount, email, reference, auth_url, access_code) => {
    setRequest({amount: amount, email: email, reference: reference, auth_url: auth_url, access_code: access_code})
  }

  const views = [ 
    <StepOne setRecap={setRecap} setHeight={setHeight} secretKey={secretKey} updateCode={updateCode} updateLanguage={updateLanguage}
      updateSecretKey={updateSecretKey} changeStep={updateStep}  />, 
    <StepTwo recap={recap} setHeight={setHeight} secretKey={secretKey} updateCode={updateCode} 
      updateLanguage={updateLanguage} updateRequest={updateRequest} changeStep={updateStep} />,
    <StepThree recap={recap} setHeight={setHeight} access_code={request.access_code} changeStep={updateStep} updateEndpoint={updateCurrentEndpoint} />, 
    <StepFour recap={recap} setHeight={setHeight} secretKey={secretKey} updateCode={updateCode} 
      updateLanguage={updateLanguage} updateAuthCode={updateAuthCode} request={request} changeStep={updateStep} />, 
    <StepFive recap={recap} setHeight={setHeight} secretKey={secretKey} authCode={authCode} email={request.email}
      updateCode={updateCode} updateLanguage={updateLanguage} updateEndpoint={updateCurrentEndpoint} changeStep={updateStep} />, 
    <StepSix changeStep={updateStep} updateEndpoint={updateCurrentEndpoint} updateSecretKey={updateSecretKey} />
  ]

  return (
    <Container>
      {views[currentStep - 1]}
    </Container>
  )
}


export default Steps