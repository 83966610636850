import React from 'react'
import styled from "styled-components"

import ApiLabel from '../ApiLabel/ApiLabel'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 0px;

  .code-lang {
    font-size: 13px;
  }
`

const URL = styled.div`
  display: flex;

  div:last-child {
    margin-left: 8px;
    color: #011B33;
  }
`

const Header = ({ endpoint, language }) => {
  const BASE_URL =  'api.paystack.co'

  return (
    <Container>
      <URL>
        <ApiLabel method={endpoint.method}/>
        <div>
          <span>{BASE_URL}{endpoint.path}</span>
        </div>
      </URL>
      <div>
        <span className="code-lang">{ language === 'bash' ? 'cURL' : 'JSON' }</span>
      </div>
    </Container>
  )
}


export default Header