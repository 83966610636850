import React, { useState } from 'react'
import styled from "styled-components"

import Steps from './Steps'
import Header from './Header'
import CodeSnippet from './CodeSnippet'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin: 30px 0;
  background: #FFFFFF;
  border: 1px solid #0000000f;
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
  height: 100%;

  h4 {
    margin-top: 0;
  }

  .left {
    flex: 0 0 30%;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background-color: #EEF2F5;
    box-sizing: border-box;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
  }

  .right {
    flex: 0 0 30%;
  }

  @media screen and (max-width: 767px) {
    display: block;

    .left {
      border-top-right-radius: 6px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding-bottom: 20px;
    }
	}
`

const TransferWalkthrough = () => {
  const [endpoint, setEndpoint] = useState({ method: 'post', path: '/transferrecipient' })
  const [language, setLanguage] = useState('bash')
  const [code, setCode] = useState('')
  const [height, setHeight] = useState('')

  
  const updateEndpoint = (endpoint) => {
    setEndpoint(endpoint)
  }

  const updateLanguage = (language) => {
    setLanguage(language)
  }

  const updateCode = (code) => {
    setCode(code)
  }


  return (
    <Container>
      <div className="left">
        <Steps setHeight={setHeight} updateEndpoint={updateEndpoint} updateLanguage={updateLanguage} updateCode={updateCode} />
      </div>
      <div className="right">
        <Header endpoint={endpoint} language={language} />
        <CodeSnippet height={height} language={language} codeString={code} theme={"dark"} />
      </div>
    </Container>
  )
}


export default TransferWalkthrough