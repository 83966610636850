import React from 'react'
import styled from "styled-components"


const Container = styled.div`
  display: flex;
  align-items: center;
  background: ${props => props.theme.bg};
  color: ${props => props.theme.fg};
  padding: 4px 8px;
  border-radius: 100px;

  span {
    font-weight: 500;
    font-size: 11px;
    line-height: 1.2;
    text-transform: uppercase;
  }
`

const ApiLabel = ({ method }) => {

  const colors = {
    post: {bg: '#E4F6E7', fg: '#177532'},
    put: {bg: '#ffedd6', fg: '#c17d25'},
    get: {bg: '#c7ebff', fg: '#011b33'},
    delete: {bg: '#fdd6d6', fg: '#a03939'},
  }

  return (
    <Container theme={colors[method]}>
      <span>{method}</span>
    </Container>
  )
}


export default ApiLabel