import React, { useState, useEffect } from 'react'
import styled from "styled-components"
import axios from 'axios'

import StepOne from './Steps/StepOne'
import StepTwo from './Steps/StepTwo'
import StepThree from './Steps/StepThree'
import StepFour from './Steps/StepFour'
import StepFive from './Steps/StepFive'


const Container = styled.div`
  padding: 16px 8px;
`

const Steps = props => {
  const [currentStep, updateCurrentStep] = useState(1)
  const [secretKey, setSecretKey] = useState('YOUR_SECRET_KEY')
  const [country, setCountry] = useState('')
  const [countries, setCountries] = useState([])
  const [accountName, setAccountName] = useState('')
  const [recipientCode, setRecipientCode] = useState('')
  const [recap, setRecap] = useState({})

  const endpoints = {
    transferrecipient: { method: 'post', path: '/transferrecipient' },
    transfer: { method: 'post', path: '/transfer' }
  }

  const updateStep = (step) => {
    updateCurrentStep(step)
  }

  const updateSecretKey = (key) => {
    setSecretKey(key)
  }

  const updateCurrentEndpoint = (type) => {
    props.updateEndpoint(endpoints[type])
  }

  useEffect(() => {
    props.setHeight('240px')
  }, []);

  const views = [ 
    <StepOne recap={recap} setRecap={setRecap} setCountries={setCountries} setHeight={props.setHeight} secretKey={secretKey} updateCode={props.updateCode} updateLanguage={props.updateLanguage}
      updateSecretKey={updateSecretKey} changeStep={updateStep}  />, 
    <StepTwo recap={recap} country={country} countries={countries} setCountry={setCountry} changeStep={updateStep} />,
    <StepThree recap={recap} country={country} setAccountName={setAccountName} setRecipientCode={setRecipientCode} setHeight={props.setHeight} changeStep={updateStep} 
      updateCode={props.updateCode} updateLanguage={props.updateLanguage} />, 
    <StepFour recap={recap} accountName={accountName} recipientCode={recipientCode} updateEndpoint={updateCurrentEndpoint} 
      setHeight={props.setHeight} updateCode={props.updateCode} updateLanguage={props.updateLanguage} changeStep={updateStep} />,
    <StepFive changeStep={updateStep} updateEndpoint={updateCurrentEndpoint} setHeight={props.setHeight} updateSecretKey={updateSecretKey} />
  ]

  return (
    <Container>
      {views[currentStep - 1]}
    </Container>
  )
}


export default Steps