import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import StepIcon from '../../../assets/images/svg/info.svg';

const Container = styled.div`
  padding: 0px 8px;
`

const Header = styled.div`
  margin-bottom: 10px;

  svg {
    width: 20px;
    height: 20px;
  }
`

const Content = styled.div`
  min-height: 240px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  h4 {
    margin-bottom: 12px
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: #285056;
  }
`
const Footer = styled.div`
  button {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`

const StepOne = props => {

  const [cachedResponse, setcachedResponse] = useState(false)

  const url = process.env.GATSBY_MIDDLEWARE_URL

  const fetchRecap = () => {
    axios.get(`${url}/transfer/recap`).then(res => {

      if(Object.keys(res.data).length < 5 && res.data.constructor === Object) {
        fetchCountries()
        setcachedResponse(false)
      } else {
        props.setRecap(res.data)
        setcachedResponse(true)
      }
    }).catch( () => {
      props.setRecap({})
    })
  }

  const fetchCountries = () => {
    if(!cachedResponse) {
      axios.get(`${url}/country`).then(res => {
        props.setCountries(res.data.data)
      }).catch(error => {
        console.log("Error: ", error.response)
      })
    }
    
  }

  const autoGenerateKey = () => {
    props.updateSecretKey('sk_test_DEFAULT')
    setCodeString('sk_test_DEFAULT')
    props.changeStep(2)
  }

  const setCodeString = (secret_key) => {
    const curl = `curl https://api.paystack.co/transferrecipient 
    -H "Authorization: Bearer ${secret_key}"
    -H "Content-Type: application/json"
    -X POST
    `

    props.updateCode(curl)
    props.updateLanguage('bash')
  }

  useEffect(() => {
    props.setHeight('240px')
    fetchRecap()
  }, [])

  useEffect(() => {
    setCodeString(props.secretKey)
  }, [props.secretKey]);

  return (
    <Container>
      <Header>
        <StepIcon />
      </Header>
      <Content>
        <div>
          <h4>Before you begin</h4>
          <p>Authenticate all Paystack API calls using your secret keys</p>
        </div>
        <Footer>
          <button type="button" className="button" onClick={() => autoGenerateKey()}>Next</button>
        </Footer>
      </Content>
    </Container>
  )
}


export default StepOne