import React from 'react';

import Layout from '../components/Layout/Layout';
import SEO from '../components/seo';
import Home from '../components/Home/Home';

import '../assets/styles/global.scss';

export default () => {
	const fields = {};
	fields.slug = '/';
	const tableOfContents = {};

	return (
		<div>
			<SEO title="Home" />
			<Layout>
				{tableOfContents}
				<Home />
				{fields}
			</Layout>
		</div>
	);
};
