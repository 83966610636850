import React from 'react';

import Permalink from "../Permalink/Permalink"
import QuickStartCard from './QuickStartCard'
import CardContainer from '../Cards/CardContainer';
import DemoCard from '../Cards/DemoCard';
import TransactionWalkthrough from '../TransactionWalkthrough/TransactionWalkthrough';
import TransferWalkthrough from '../TransferWalkthrough/TransferWalkthrough';

import AcceptPayment from '../../assets/images/svg/accept-payment-light.svg'
import SendMoney from '../../assets/images/svg/send-money-light.svg'
import IdentifyCustomer from '../../assets/images/svg/identify-customers-light.svg'
import OtherWays from '../../assets/images/svg/other-ways-light.svg'

import './home.scss';

const Home = () => {
	return (
		<>
			<header>
				<h1>Paystack Documentation</h1>
				<p>
					Welcome to the Paystack Developer Documentation where you’ll learn how
					to build amazing payment experiences with the Paystack API.
				</p>
			</header>
			<section>
				<Permalink type="h2" title="Quick start" id="quick-start" />
				<CardContainer>
					<QuickStartCard title='Accept Payments' route= '/payments' image={<AcceptPayment />}
						description="Collect payments from cards, bank and mobile money accounts"/>
					<QuickStartCard title='Send Money' route='/transfers' image={<SendMoney />}
						description="Make instant transfers to bank accounts and mobile money numbers"/>
					<QuickStartCard	title='Identify your Customers' route='/identity-verification' image={<IdentifyCustomer />}
						description="Verify phone numbers, bank accounts or Bank Verification Numbers (BVN)" />
					<QuickStartCard title='Other ways to use Paystack' route='/libraries-and-plugins' image={<OtherWays />}
						description="Explore plugins, libraries and tools for accepting payments without the API" />
				</CardContainer>
			</section>
			<section>
				<Permalink type="h2" title="Accept a payment" id="accept-a-payment" />
				<p>Here’s how to use the Paystack API to accept a payment</p>
				<TransactionWalkthrough />
			</section>
			<section>
				<Permalink type="h2" title="Make a transfer" id="make-a-transfer" />
				<p>Here’s how quickly you can send  money on Paystack</p>
				<TransferWalkthrough />
			</section>
			<section>
				<Permalink type="h2" title="Explore demos" id="explore-demos" />
				<p>
					We’ve put together simple projects to demonstrate how to use the
					Paystack API for various financial services. <a href="https://github.com/PaystackOSS/" 
					target="_blank" rel="noopener noreferrer">Explore all demos</a> or
					start with the most popular ones below:
				</p>
				<CardContainer>
					<DemoCard
						title="Gift Store"
						link="PaystackOSS/sample-gift-store"
						apis={[
							{ title: 'Accept Payments', route: '/payments/accept-payments' },
							{ title: 'Verify Payments', route: '/payments/verify-payments' },
						]}
					/>
					<DemoCard
						title="Movie Ticket"
						link="PaystackOSS/sample-movie-ticket"
						apis={[
							{ title: 'Accept Payments', route: '/payments/accept-payments' },
							{ title: 'Verify Payments', route: '/payments/verify-payments' },
						]}
					/>
				</CardContainer>
			</section>
		</>
	);
};

export default Home;
