import React, { useState, useEffect } from 'react'
import styled from "styled-components"
import axios from 'axios'

import StepIcon from '../../../assets/images/svg/back-arrow.svg';

const Container = styled.div`
  padding: 0 8px;
`

const Header = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 24px;

  span {
    font-size: 14px;
    line-height: 14px;
    margin-left: 6px;
  }
`

const Content = styled.div`
  height: 270px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  h4 {
    margin-bottom: 8px
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: #285056;
  }
`
const Footer = styled.div`
  div:last-child {
    margin-bottom: 0;
  }

  div {
    margin-bottom: 8px;

    input {
      width: calc(100% - 16px);
      height: 30px;
      padding: 4px 8px;
      font-family: var(--regular-font);
      font-size: 14px;
      border: 1px solid #EEF2F5;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
      border-radius: 6px;
      outline: none;
  
      &:focus {
        border: 1px solid rgba(130, 130, 130, 0.2);
        outline: none;
      }
      &:disabled {
        opacity: 0.7;
        background-color: #fff;
      }
    }

    select {
      width: 100%;
      height: 35px;
      padding: 4px 8px;
      font-family: var(--regular-font);
      font-size: 14px;
      border: 1px solid #EEF2F5;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
      border-radius: 6px;
      outline: none;
    }

    label {
      font-weight: 500;
      font-size: 13px;
      display: inline-block;
      margin-bottom: 4px;
    }
  
    button {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 20px;
    }
  }
`

const Spinner = styled.span`
  width: 13px;
  height: 13px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  border-top: 2px solid #fff;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`

const StepThree = props => {

  const [account, setAccount] = useState('')
  const [bankCode, setBankCode] = useState('')
  const [bank, setBank] = useState('')
  const [banks, setBanks] = useState([])
  const [loading, setLoading] = useState(false)
  const [inputIsValid, setInputIsValid] = useState(false)
  const [cachedResponse, setcachedResponse] = useState(false)
  const url = process.env.GATSBY_MIDDLEWARE_URL
  let accountName = ''

  const fetchBanks = () => {
    axios.get(`${url}/bank?country=${props.country}`).then(res => {
      setBanks(res.data.data)
    }).catch(error => {
      console.log("Error: ", error.response.data)
    })
  }

  const checkRecap = () => {
    if(Object.keys(props.recap).length === 0 && props.recap.constructor === Object) {
      setcachedResponse(false)
      fetchBanks()
    } else {
      let request = props.recap['bank/resolve_account_number'].request
      let banks = props.recap.bank_country.response.data
      let queryParams = getQueryParams(request)
      let bankObj = banks.find(({code}) => code === queryParams.bank_code)

      setBankCode(queryParams.bank_code)
      setBank(bankObj.name)
      setAccount(queryParams.account_number)

      validateForm(queryParams.bank_code, queryParams.account_number)
      setcachedResponse(true)
    }
  }

  const loadCachedResponse = () => {
    let response = props.recap['bank/resolve_account_number'].response
    let recipient = props.recap.transferrecipient.response
    props.setAccountName(response.data.account_name)
    props.setRecipientCode(recipient.data.recipient_code)
    props.changeStep(4)
  }

  const getQueryParams = (url) => {
    let queryParams = {};
    let anchor = document.createElement('a')
    anchor.href = url
  
    let queryStrings = anchor.search.substring(1)
    let params = queryStrings.split('&');

    for (let i = 0; i < params.length; i++) {
        let pair = params[i].split('=')
        queryParams[pair[0]] = decodeURIComponent(pair[1])
    }

    return queryParams
  }

  const resolveAccount = () => {
    if(cachedResponse) {
      loadCachedResponse()
    } else {
      setLoading(true)
      axios.get(`${url}/bank/resolve?account_number=${account}&bank_code=${bankCode}`).then(res => {
        if(res.data.data.account_name){
          accountName = res.data.data.account_name
          props.setAccountName(accountName)
          createTransferRecipient()
        }
      }).catch(error => {
        console.log("Error: ", error.response)
      }).then(() => {
        setLoading(false)
      })
    }
  }

  const createTransferRecipient = () => {
    const data = {
      type: 'nuban',
      name: accountName,
      account_number: account,
      bank_code: bankCode
    }

    axios.post(`${url}/transferrecipient`, data).then(res => {
      props.updateCode(JSON.stringify(res.data, null, '  '))
      props.updateLanguage('json')
      if(res.data.data.recipient_code){
        props.setRecipientCode(res.data.data.recipient_code)
        props.changeStep(4)
      }
    }).catch(error => {
      console.log("Error: ", error.response)
    }).then(() => {
      setLoading(false)
    })
  }

  const updateBank = (event) => {
    setBankCode(event.target.value)
    validateForm(event.target.value, account)
  }

  const updateAccount = (event) => {
    setAccount(event.target.value)
    validateForm(bankCode, event.target.value)
  }

  const validateForm = (bankCode, account) => {
    setInputIsValid(bankCode.length > 0 && account.length >= 10)
  }

  useEffect(() => {
    props.setHeight('272px')
    // fetchBanks()
    checkRecap()
  }, []);


  return (
    <Container>
      <Header onClick={() => props.changeStep(2)}>
        <StepIcon />
        <span>Back</span>
      </Header>
      <Content>
        <div>
          <h4>Choose Bank</h4>
          <p>Enter transfer recipient’s bank information</p>
        </div>
        <Footer>
          <div>
            <label>Bank</label>
            <select value={bankCode} onChange={updateBank} disabled={cachedResponse}>
            {cachedResponse ? 
                <option value={bankCode}>{bank}</option> : 
                <option value="">Select bank</option>
              }
              {banks.map((bank, index) => (
                <option key={index} value={bank.code}>{bank.name}</option>
              ))}
            </select>
          </div>
          <div>
            <label>Account number</label>
            <input type="number" placeholder="0123456789" value={account} onChange={updateAccount} disabled={ cachedResponse }/>
          </div>
          <div>
            <button className="button" onClick={() => resolveAccount()} disabled={ !inputIsValid || loading}>
              {loading ? <Spinner /> : cachedResponse ? 'Next' : 'Resolve account' }
            </button>
          </div>
        </Footer>
      </Content>
      
    </Container>
  )
}


export default StepThree